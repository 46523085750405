<script>
import postResendWelcomeEmail from '@/api/saleReferrals/postResendWelcomeEmail';

export default {
  name: 'AdminResendWelcomeEmail',
  data: function () {
    return {
      reference: '',
      loading: false,
      successful: false,
      error: false,
    };
  },
  methods: {
    resendWelcomeEmail: async function () {
      this.successful = false;
      this.error = false;
      this.loading = true;
      await postResendWelcomeEmail({ reference: this.reference }).catch(() => {
        this.error = true;
      });

      this.successful = true;
      this.loading = false;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header bg-light">
      <h6 class="card-title">Resend Welcome Email</h6>
    </div>

    <div class="card-body">
      <p>
        This form grants the ability to resend a welcome email to a user, upon submitting the form a password will be
        automatically generated and emailed to the user allowing them to log in.
      </p>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group row mt-2">
            <label class="col-form-label col-lg-2" for="reference">Case Reference</label>
            <div class="col-lg-4">
              <input id="reference" class="form-control" v-model="reference" v-on:keyup.enter="resendWelcomeEmail" />
            </div>
            <div class="col-lg-4">
              <button class="btn btn-primary" @click="resendWelcomeEmail" :disabled="reference === '' || loading">
                Resend Welcome Email
                <i v-if="loading" class="icon-spinner11 ml-2 spinner"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="successful || error">
        <div class="col">
          <p class="bg-green p-2" v-if="successful">Welcome email successfully sent.</p>
          <p class="bg-warning p-2" v-if="error">Error sending welcome email.</p>
        </div>
      </div>
    </div>
  </div>
</template>
